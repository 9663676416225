<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          label-position="right"
          label-width="110px"
          :model="formInline"
          class="demo-form-inline"
        >
          <el-form-item label="支付起止日期" label-width="100px">
            <el-date-picker
              v-model="date1"
              type="date"
              placeholder="选择日期"
              @change="showLog"
            ></el-date-picker>
            至
            <el-date-picker
              v-model="date2"
              type="date"
              placeholder="选择日期"
              @change="showLog"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="是否存在差异">
            <el-select v-model.trim="formInline.hasDif" filterable placeholder="">
              <el-option label="全部" value="-1"></el-option>
              <el-option label="是" value="1"></el-option>
              <el-option label="否" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="平账进度">
            <el-select v-model.trim="formInline.dealStatus" filterable placeholder="">
              <el-option label="全部" value=""></el-option>
              <el-option label="未平账" value="0"></el-option>
              <el-option label="已平账" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchData();
            "
            :loading="loading"
            >查询
          </el-button>
        </el-form>
      </div>
      <el-tabs type="card" v-model.trim="formInline.billType" @tab-click="handleTab">
        <el-tab-pane v-if="authority.tabs['wx']" label="微信" name="2">
          <!--列表区域-->
          <div class="tableWrapper">
            <el-table
              border
              v-loading="loading"
              :data="tableData"
              style="width: 100%"
              ref="singleTable"
              highlight-current-row
            >
              <el-table-column
                align="center"
                type="index"
                :label="$t('list.index')"
                width="70"
              ></el-table-column>
              <el-table-column
                align="center"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                v-for="item in tableCols"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
              <el-table-column
                v-if="
                  authority.tabs['wx'].button.downloadDifferenceFile ||
                  authority.tabs['wx'].button.downloadMyFile ||
                  authority.tabs['wx'].button.downloadOtherFile
                "
                align="center"
                label="操作"
                width="150"
              >
                <template slot-scope="scope">
                  <!--<el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>-->
                  <el-dropdown
                    style="color: #20a0ff; cursor: pointer"
                    @command="ctrlData($event, scope.$index, scope.row)"
                  >
                    <span class="el-dropdown-link">
                      下载账单明细<i class="el-icon-caret-bottom el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-if="authority.tabs['wx'].button.downloadDifferenceFile"
                        command="differ"
                        >下载差异文件
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="authority.tabs['wx'].button.downloadMyFile"
                        command="wf"
                      >
                        下载我方对账文件
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="authority.tabs['wx'].button.downloadOtherFile"
                        command="df"
                      >
                        下载对方对账文件
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="支付宝" v-if="authority.tabs['alipay']" name="1">
          <div class="tableWrapper">
            <el-table
              border
              v-loading="loading"
              :data="tableData"
              style="width: 100%"
              ref="singleTable"
              highlight-current-row
            >
              <el-table-column
                align="center"
                type="index"
                :label="$t('list.index')"
                width="70"
              ></el-table-column>
              <el-table-column
                align="center"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                v-for="item in tableCols"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
              <el-table-column
                v-if="
                  authority.tabs['alipay'].button.downloadDifferenceFile ||
                  authority.tabs['alipay'].button.downloadMyFile ||
                  authority.tabs['alipay'].button.downloadOtherFile
                "
                align="center"
                label="操作"
                width="150"
              >
                <template slot-scope="scope">
                  <!--<el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>-->
                  <el-dropdown
                    style="color: #20a0ff; cursor: pointer"
                    @command="ctrlData($event, scope.$index, scope.row)"
                  >
                    <span class="el-dropdown-link">
                      下载账单明细<i class="el-icon-caret-bottom el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-if="authority.tabs['alipay'].button.downloadDifferenceFile"
                        command="differ"
                        >下载差异文件
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="authority.tabs['alipay'].button.downloadMyFile"
                        command="wf"
                      >
                        下载我方对账文件
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="authority.tabs['alipay'].button.downloadOtherFile"
                        command="df"
                      >
                        下载对方对账文件
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane v-if="authority.tabs['unionPay']" label="银联" name="3">
          <!--列表区域-->
          <div class="tableWrapper">
            <el-table
              border
              v-loading="loading"
              :data="tableData"
              style="width: 100%"
              ref="singleTable"
              highlight-current-row
            >
              <el-table-column
                align="center"
                type="index"
                :label="$t('list.index')"
                width="70"
              ></el-table-column>
              <el-table-column
                align="center"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                v-for="item in tableCols"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
              <el-table-column
                v-if="
                  authority.tabs['unionPay'].button.downloadDifferenceFile ||
                  authority.tabs['unionPay'].button.downloadMyFile ||
                  authority.tabs['unionPay'].button.downloadOtherFile
                "
                align="center"
                label="操作"
                width="150"
              >
                <template slot-scope="scope">
                  <!--<el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>-->
                  <el-dropdown
                    style="color: #20a0ff; cursor: pointer"
                    @command="ctrlData($event, scope.$index, scope.row)"
                  >
                    <span class="el-dropdown-link">
                      下载账单明细<i class="el-icon-caret-bottom el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-if="authority.tabs['unionPay'].button.downloadDifferenceFile"
                        command="differ"
                        >下载差异文件
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="authority.tabs['unionPay'].button.downloadMyFile"
                        command="wf"
                      >
                        下载我方对账文件
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="authority.tabs['unionPay'].button.downloadOtherFile"
                        command="df"
                      >
                        下载对方对账文件
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="爱泊车" v-if="authority.tabs['aiPay']" name="4">
          <div class="tableWrapper">
            <el-table
              border
              v-loading="loading"
              :data="tableData"
              style="width: 100%"
              ref="singleTable"
              highlight-current-row
            >
              <el-table-column
                align="center"
                type="index"
                :label="$t('list.index')"
                width="70"
              ></el-table-column>
              <el-table-column
                align="center"
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                v-for="item in tableCols"
                :key="item.prop"
                :formatter="item.formatter"
              ></el-table-column>
              <el-table-column
                v-if="
                  authority.tabs['aiPay'].button.downloadDifferenceFile ||
                  authority.tabs['aiPay'].button.downloadMyFile ||
                  authority.tabs['aiPay'].button.downloadOtherFile
                "
                align="center"
                label="操作"
                width="150"
              >
                <template slot-scope="scope">
                  <!--<el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>-->
                  <el-dropdown
                    style="color: #20a0ff; cursor: pointer"
                    @command="ctrlData($event, scope.$index, scope.row)"
                  >
                    <span class="el-dropdown-link">
                      下载账单明细<i class="el-icon-caret-bottom el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-if="authority.tabs['aiPay'].button.downloadDifferenceFile"
                        command="differ"
                        >下载差异文件
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="authority.tabs['aiPay'].button.downloadMyFile"
                        command="wf"
                      >
                        下载我方对账文件
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-if="authority.tabs['aiPay'].button.downloadOtherFile"
                        command="df"
                      >
                        下载对方对账文件
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateFormat, exportExcelNew } from "@/common/js/public.js";

export default {
  name: "wayStatistics",
  data() {
    return {
      authority: this.$route.meta.authority,
      index: 0,
      total: 0,
      date1: "",
      date2: "",
      pageSize: 15,
      pageNum: 1,
      loading: false,
      detailFlag: false,
      isDisabled: true,
      areaList: [],
      parkList: [],
      berthList: [],
      tableCols: [
        {
          prop: "billDate",
          label: "支付日期",
          width: "",
          formatter: (row, column, value) => {
            return dateFormat(new Date(value), "yyyy-MM-dd");
          },
        },
        {
          prop: "sysMoney",
          label: "我方交易总金额",
          width: "",
          formatter: (row, column) => {
            return row.sysMoney == 0 ? "0.00元" : row.sysMoney / 100 + "元";
          },
        },
        {
          prop: "billMoney",
          label: "对方交易总金额",
          width: "",
          formatter: (row, column) => {
            return row.billMoney == 0 ? "0.00元" : row.billMoney / 100 + "元";
          },
        },
        {
          prop: "difMoney",
          label: "差额",
          width: "",
          formatter: (row, column) => {
            return row.difMoney == 0 ? "0.00元" : row.difMoney / 100 + "元";
          },
        },
        {
          prop: "hasDif",
          label: "是否存在差异",
          width: "",
          formatter: (row) => {
            if (row.hasDif) {
              return "是";
            } else {
              return "否";
            }
          },
        },
        {
          prop: "dealStatus",
          label: "平账进度",
          width: "",
          formatter: (row) => {
            if (row.dealStatus) {
              return "已平账";
            } else {
              return "未平账";
            }
          },
        },
      ],
      tableData: [],
      dateRange: [],
      formInline: {
        startTime: "",
        endTime: "",
        billType: "2",
        hasDif: "-1",
        dealStatus: "",
      },
    };
  },
  methods: {
    // 处理操作相关动作
    ctrlData(command, index, obj) {
      if (command == "wf") {
        this.downloadSys(obj);
      } else if (command == "df") {
        this.downloadChannel(obj);
      } else if (command == "differ") {
        this.downloadDif(obj);
      }
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    showLog() {
      if (this.date1 && this.date2) {
        let startTime = dateFormat(this.date1, "yyyy-MM-dd");
        let endTime = dateFormat(this.date2, "yyyy-MM-dd");
        this.formInline.startTime = startTime;
        this.formInline.endTime = endTime;
        if (startTime > endTime) {
          this.$alert("开始时间不能大于结束时间", this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
          return false;
        } else {
          return true;
        }
      } else {
        this.formInline.startTime = "";
        this.formInline.endTime = "";
        return true;
      }
    },
    // 下载差异文件
    downloadDif(data) {
      exportExcelNew("/acb/2.0/bill/diffDownload", {
        payType: this.formInline.billType,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      });
    },
    // 下载我方对账文件
    downloadSys(data) {
      exportExcelNew("/acb/2.0/bill/download", {
        payType: this.formInline.billType,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      });
    },
    // 下载对方对账文件
    downloadChannel(data) {
      exportExcelNew("/acb/2.0/bill/otherBilldownload", {
        payType: this.formInline.billType,
        billDate: dateFormat(new Date(data.billDate), "yyyy-MM-dd"),
      });
    },
    handleTab(tab) {
      this.formInline.billType = tab.name;
      this.searchData();
    },
    // 搜索
    searchData() {
      if ((this.date1 && !this.date2) || (!this.date1 && this.date2)) {
        this.$alert("必须选择两个日期", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }

      let flag = this.showLog();
      this.tableData = [];
      this.formInline.page = this.pageNum;
      this.formInline.pageSize = this.pageSize;

      if (flag) {
        this.loading = true;
        this.$axios
          .get("/acb/2.0/bill/list", {
            data: this.formInline,
          })
          .then((res) => {
            if (res.state == 0) {
              this.loading = false;
              this.tableData = res.value.list;
              this.total = res.value.total * 1 || 0;
            } else {
              this.loading = false;
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
    },
  },
  components: {},
  activated() {
    this.searchData();
  },
  created() {},
  mounted() {
    // this.searchData();
    // console.log(this.authority);
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.content
  background #FFFFFF
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    margin-bottom 20px
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    float right
    .export
      font-size 12px
      .iconfont
        margin-right 0
  .btnWrapper
    text-align left
    margin-top 28px
    float left
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
</style>
